import React from 'react'
import EventForm from './EventForm'
import { message } from 'antd'
import { inject } from 'mobx-react'
import { useMutation, useQuery } from '@apollo/client'
import eventQuery from '~/graphql/queries/event.gql'
import updateEventMutation from '~/graphql/mutations/updateEvent.gql'
import compose from 'recompose/compose'

const EventEdit = ({ user, router, match: { params: { id } } }) => {
  // console.log({ match })
  const { data, loading, error } = useQuery(eventQuery, {
    variables: { _id: id },
    fetchPolicy: 'network-only'
  })
  const [updateEvent] = useMutation(updateEventMutation)
  if (loading) {
    return 'loading...'
  }
  if (error) {
    return 'error'
  }
  const { event: { _id: _, ...record } } = data
  // console.log(user, record.createdByUserId)
  if(!['superadmin', 'admin', 'operation_admin'].includes(user.role)&& user._id !== record.createdByUserId){
    router.replace('/events')
  }
  // if ((user.role !== 'superadmin' || user.role !== 'admin') && user._id !== record.createdByUserId) {
  //   router.replace('/events')
  // }
  // TODO: handle event not found
  const handleSubmit = async (values) => {
    // TODO: try catch
    const { data: response } = await updateEvent({
      variables: {
        _id: id,
        createdByUserId: id,
        ...values
      }
    })
    message.info(`Event "${response?.updateEvent?.record?.title}" updated`, 10)
    router.push('/events')
  }
  const defaultValues = {
    record
  }
  return <EventForm onSubmit={handleSubmit} defaultValues={defaultValues} resourceId={id} />
}

export default compose(
  inject('router'),
  inject('user')
)(EventEdit)
