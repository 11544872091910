import React, { Component, Suspense } from 'react'
import { Route, Switch, Link, Redirect, withRouter } from 'react-router-dom'
import { graphql, withApollo } from '@apollo/client/react/hoc'
// import _get from 'lodash/get'

import { compose, withProps } from 'recompose'
import { message, Button, Alert, Popover, Avatar } from 'antd'
import Icon, {
  BarsOutlined,
  CopyrightOutlined,
  DollarOutlined,
  MessageOutlined,
  PercentageOutlined,
  PictureOutlined,
  PieChartOutlined,
  PlusCircleOutlined,
  TagOutlined,
  UserOutlined,
  WalletOutlined
  // BellOutlined
} from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import Linkify from 'react-linkify'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
// import 'react-pro-sidebar/dist/css/styles.css';

import { AppWrapper } from '~/styles'
import { MainMenu } from '~/styles/mainmenu'
import '~/styles/global.css'
// import RunnerIcon from '~/assets/icons/runner.svg'
import RunnerIcon from '~/icons/Runner'

import Dashboard from './components/Dashboard'
// import OrganizerBalance from './components/OrganizerBalance'
import Orders from './pages/Orders'
import MonthStats from './pages/MonthStats'
// import MonthsOrders from './components/MonthsOrders'
// import MonthsOrdersDetail from './components/MonthsOrdersDetail'
import EventsOrders from './components/EventsOrders'
// import PeriodOrders from './components/PeriodOrders'
import Order from './components/Order'
import Events from './components/Events'
import EventCreate from './components/EventCreate'
import EventEdit from './components/EventEdit'
import Event from './components/Event'
import EventPhotos from './components/EventPhotosN'
import EventView from './components/EventViewN'
import EventCreditStats from './components/EventCreditStats'
import PhotoUpload from './components/PhotoUploadN'
import PhotoTag from './components/PhotoTag'
import Photo from './components/Photo'
// import logo from './assets/images/logo.png'
import ViewProfile from './components/ViewProfile'
// import EditProfile from './components/EditProfile'
import Announcements from './components/Announcements'
import AnnouncementCreate from './components/AnnouncementCreate'
// import BankProfile from './components/BankProfile'
// import Transfer from './components/Transfer'
import TransferRequestList from './components/TransferRequestList'
import TransferRequestCreate from './components/TransferRequestCreate'
import TransferRequestEventCreate from './components/TransferRequestEventCreate'
import UserList from './components/UserList'
import EditUser from './components/EditUser'
import UserCreateAdmin from './components/UserCreateAdmin'
// import EventSummary from './components/EventSummary'
import EventSummary from './pages/EventSummary'
// import MonthSummary from './components/MonthSummary'
import LocaleSwitcher from '~/components/LocaleSwitcher'
import { inject, observer } from 'mobx-react'
import ReportedPhotos from './components/ReportedPhotos'
import Partners from './components/Partners'
import PartnerAdd from './components/PartnerAdd'
import PartnerEdit from './components/PartnerEdit'
import OrganizerEvents from './components/OrganizerEvents'
import Brokers from './components/Brokers'
import BrokerAdd from './components/BrokerAdd'
import BrokerEdit from './components/BrokerEdit'
import BrokerEvents from './components/BrokerEvents'
import Salespersons from './components/Salespersons'
import Photographers from './components/Photographers'
import SalespersonEvents from './components/SalespersonEvents'
import PhotographerEvents from './components/PhotographerEvents'
import CouponTypes from './components/CouponTypes'
import Coupons from './components/Coupons'
import CouponTypeCreate from './components/CouponTypeCreate'
import CouponTypeEdit from './components/CouponTypeEdit'
import GenerateCoupons from './components/GenerateCoupons'
import CouponUsedList from './components/CouponUsedList'

import announcementQuery from '~/graphql/queries/announcement.gql'
import announcementsQuery from '~/graphql/queries/announcements.gql'
// import adminUserQuery from '~/graphql/queries/adminUser.gql'
import CreditHistory from './components/CreditHistory'
import AddEventCredits from './components/AddEventCredits'
import BuyCredits from './components/BuyCredits'
// import Balance from './components/Balance'
import CampaignConsent from '~/components/CampaignConsent'
// import moment from 'moment-timezone'
// import UserPermission from './components/UserPermission'
import Packages from './components/Packages'
import PackageEdit from './components/PackageEdit'
import PackageCreate from './components/PackageCreate'
import './fontawesome'
import Mergeorganizers from './components/Mergeorganizers'
import config from './config'
// import { LayoutContent } from '~/styles'

// const { SubMenu } = AntMenu
const UserEdit = compose(
  inject('user'),
  withRouter,
  withProps(({ match, ...props }) => ({ ...props, userId: match.params.userId }))
)(EditUser)
const UserProfileEdit = compose(
  inject('user'),
  withProps(({ user, ...props }) => ({ ...props, userId: user._id }))
)(EditUser)

// import './App.css'
message.config({
  // top: 75,
  duration: 15
})

// const menuProfile = (
//   <Menu>
//     <Menu.Item key="view-profile"><Link to="/profile">View Profile</Link></Menu.Item>
//     <Menu.Item key="edit-profile"><Link to="/profile/edit">Edit Profile</Link></Menu.Item>
//   </Menu>
// )
@inject('user')
@graphql(announcementQuery, {
  name: 'announcement',
  options: ({user}) => ({
    variables: {
      userRole: user.role,
      limit: 5
    }
  })
})
@graphql(announcementsQuery, {
  name: 'announcementList',
  options: () => ({
    variables: {
      limit: 5
    }
  })
})

// @graphql(adminUserQuery, {
//   name: 'adminUser',
//   options: props => ({
//     variables: {
//       id: props.user._id
//     },
//     fetchPolicy: 'network-only'
//   })
// })
@withRouter
@observer
class App extends Component {
  state = {
    collapsed: false,
    toggled: false,
    modalOpen: true,
    matches: window.matchMedia('(max-width: 750px)').matches
  }
  componentDidUpdate () {
    window.matchMedia('(max-width: 750px)').addEventListener('change', e => this.setState({ matches: e }))
  }

logout = () => {
  this.props.user.logout()
  this.props.history.push('/login')
}

  onClose = () => {
    this.setState({ visible: false })
  }

  showDrawer = () => {
    this.setState({ visible: true })
  }

  // handleCollapsedChange= () => {
  //   // console.log(collapsed)
  //   this.setState({ collapsed })
  // }

  handleToggleSidebar = (value) => {
    this.setState({ toggled: value })
  }

  handleToggleClose = () => {
    this.setState({ toggled: false })
  }

  onModalClose = () => {
    this.setState({ modalOpen: false })
  }

  render () {
    // const screenHeight = window.innerHeight
    const { user, history } = this.props
    const { announcement } = this.props.announcement
    // console.log(userrole);
    // const verified = _get(this.props.adminUser, 'adminUser.verified')
    const content = <div>
      <div type="link" style={{ marginRight: '8px', textAlign: 'center' }}>
        <LocaleSwitcher />
      </div>
      <hr />
      <div style={{ width: '100%' }}>
        <Button type="link" style={{ marginRight: '8px' }}>
          <Link to="/profile">
            <FormattedMessage id="app.profile" defaultMessage="Profile" />
          </Link>
        </Button>
      </div>
      <hr />
      <div style={{ width: '100%' }}>
        <Button type="link" style={{ marginRight: '8px' }} onClick={() => this.logout()}>
          <FormattedMessage id="app.logOut" defaultMessage="Log out" />
        </Button>
      </div>
    </div>
    // const { announcements } = this.props.announcementList
    return (<React.Fragment>
      <CampaignConsent />
      {/* {config.platform === 'thai' && <Modal open={this.state.modalOpen} closable maskClosable destroyOnClose footer={null} onCancel={this.onModalClose}
        title="PHOTO.THAI.RUN ver 2.0"
        style={{ minWidth: '50vw' }}
        bodyStyle={{ padding: 0 }}
      >
        <img alt="announcement" src="https://storage.googleapis.com/assets.thai.run/photo/photothairunv2.png?v1" width="100%" />
      </Modal>} */}
      <AppWrapper>
        <div className='layout-content'>
          <MainMenu>
            <div>
              <Sidebar
                className='mobile'
                collapsed={!window.matchMedia('(max-width: 750px)').matches}
                toggled={this.state.toggled}
                onBackdropClick={this.handleToggleClose}
                breakPoint="md"
                // onClick={() => this.handleToggleClose()}
                // onCollapse={this.onCollapse}
                // screenHeight={screenHeight}
              >
                <Menu>
                  <div className="logo" style={{ display: 'inline-block' }}>
                    <Link to="/">
                      <img
                        srcSet={`/logo-small.png 64w, ${config.logo_menu} 100w`}
                        sizes='(max-width: 480px) 32px,(min-width: 480px) 100px'
                        src={config.logo_menu} alt="Thai.run"
                      />
                    </Link>
                  </div>
                </Menu>
                <Menu style={{ position: 'relative' }}>
                  {/* <Menu.Item key="dashboard"><Link to="/dashboard">Dashboard</Link></Menu.Item> */}
                  {user.can('view_dashboard') && !['heyjom', 'indo', 'malay', 'viet', 'brunei', 'mas', 'runx'].includes(config.platform) &&
                    <SubMenu
                      className='subMenu'
                      style={{ marginBottom: '10px' }}
                      label={<FormattedMessage className="menu-title" id="app.dashboard" defaultMessage="Dashboard" />}
                      icon={<div>
                        <PieChartOutlined className='menu-icon' />
                        {window.matchMedia('(max-width: 750px)').matches
                          ? ''
                          : <span className="menu-title"><FormattedMessage id="app.dashboard" defaultMessage="Dashboard" /></span>
                        }
                      </div>}
                      title={window.matchMedia('(max-width: 750px)').matches
                        ? <span className='menu-sub'><FormattedMessage id="app.dashboard" defaultMessage="Dashboard" /></span>
                        : ''
                      }
                    >
                      <Link to="/dashboard/business-advisor"><MenuItem key="business-advisor">
                        <span className='menu-item'><FormattedMessage id="app.businessAdvisor" defaultMessage="Business Advisor" /></span>
                      </MenuItem></Link>
                      <Link to="/dashboard/website"><MenuItem key="website">
                        <span className='menu-item'><FormattedMessage id="app.website" defaultMessage="Website" /></span>
                      </MenuItem></Link>
                      <Link to="/dashboard/audience"><MenuItem key="audience">
                        <span className='menu-item'><FormattedMessage id="app.audience" defaultMessage="Audience" /></span>
                      </MenuItem></Link>
                    </SubMenu>
                  }
                  <SubMenu label={<FormattedMessage className="menu-title" id="app.sales" defaultMessage="Sales" />}
                    className='subMenu'
                    style={{ marginBottom: '10px' }}
                    icon={<div>
                      <DollarOutlined className='menu-icon' />
                      {window.matchMedia('(max-width: 750px)').matches
                        ? ''
                        : <span className="menu-title"><FormattedMessage id="app.sales" defaultMessage="Sales" /></span>
                      }
                    </div>}
                    title={window.matchMedia('(max-width: 750px)').matches
                      ? <span className='menu-sub'><FormattedMessage id="app.sales" defaultMessage="Sales" /></span>
                      : ''
                    }
                  >
                    <Link to="/orders"><MenuItem key="order-list">
                      <span className='menu-item'><FormattedMessage id="app.allSales" defaultMessage="All Sales" /></span>
                    </MenuItem></Link>
                    {user.can('view_orders-month') && !['heyjom', 'indo', 'malay', 'viet', 'brunei', 'mas', 'runx'].includes(config.platform) && <Link to="/orders:months"><MenuItem key="orders-month">
                      <span className='menu-item'><FormattedMessage id="app.salesByMonth" defaultMessage="Sales by month" /></span>
                      {/* <Tooltip title={<FormattedMessage id="app.underMaintenance" defaultMessage="Under Maintenance" />}> */}
                      {/* </Tooltip> */}
                    </MenuItem></Link>}
                    {user.can('view_orders-events') && <Link to="/orders:events"><MenuItem key="orders-events">
                      <span className='menu-item'><FormattedMessage id="app.salesByEvent" defaultMessage="Sales by event" /></span>
                    </MenuItem></Link>}
                    {user.can('view_orders-shares') && <MenuItem key="orders-shares">
                      {user.role === 'organizer' ? <Link to={`/events\:balance\:organizer/${user._id}`}>{/* eslint-disable-line no-useless-escape */}
                        <span className='menu-item'><FormattedMessage id="app.salesShare" defaultMessage="Sales Share" /></span>
                      </Link>
                        : <Link to={`/events\:balance\:broker/${user._id}`}>{/* eslint-disable-line no-useless-escape */}
                          <span className='menu-item'><FormattedMessage id="app.salesShare" defaultMessage="Sales Share" /></span>
                        </Link>}
                    </MenuItem>}
                    {/* {user.role !== 'customerService' && <MenuItem key="orders-period">
                      <Link to="/orders/period">
                        <FormattedMessage id="app.salesByPeriod" defaultMessage="Sales by period" />
                      </Link>
                    </MenuItem>} */}
                  </SubMenu>
                  {/* <SubMenu key="photos" title={
                  <FormattedMessage id="app.photos" defaultMessage="Photos"/>
                }>
                  <MenuItem key="photo-list">
                    <Link to="/photos">
                      <FormattedMessage id="app.allPhotos" defaultMessage="All Photos"/>
                    </Link>
                  </MenuItem>
                  <MenuItem key="photo-upload">
                    <Link to="/photos/upload">
                      <FormattedMessage id="app.photosUpload" defaultMessage="Photos Upload"/>
                    </Link>
                  </MenuItem>
                  {(user.role === 'admin' || user.role === 'accounting') && <MenuItem key="photo-report">
                    <Link to="/photos/report">
                      <FormattedMessage id="app.photosReport" defaultMessage="Reported Photos"/>
                    </Link>
                  </MenuItem>}
                </SubMenu>
                </SubMenu> */}
                  {user.can('view_events') &&
                  <Link to="/events"><MenuItem key="event-list" className='subMenu'
                    icon={<div>
                      <Icon className="menu-icon" component={RunnerIcon} />
                      {window.matchMedia('(max-width: 750px)').matches
                        ? ''
                        : <span className="menu-title"><FormattedMessage id="app.events" defaultMessage="Events" /></span>
                      }
                    </div>}>
                    {window.matchMedia('(max-width: 750px)').matches
                      ? <span className='menu-sub'><FormattedMessage id="app.events" defaultMessage="Events" /></span>
                      : ''
                    }
                  </MenuItem></Link>
                  }
                  {user.can('view_event-create') && (
                    <>
                      {(!['photographer'].includes(user.role) || ['thai'].includes(config.platform)) && <Link to='/events/create'>
                        <MenuItem
                          key='event-create'
                          className='subMenu'
                          style={{ marginTop: '10px', marginBottom: '10px' }}
                          icon={
                            <div>
                              <PlusCircleOutlined className='menu-icon' />
                              {window.matchMedia('(max-width: 750px)').matches ? (
                                ''
                              ) : (
                                <span className='menu-title'>
                                  <FormattedMessage id='app.event' defaultMessage='Event' />
                                </span>
                              )}
                            </div>
                          }
                        >
                          {window.matchMedia('(max-width: 750px)').matches ? (
                            <span className='menu-sub'>
                              <FormattedMessage id='app.event' defaultMessage='Event' />
                            </span>
                          ) : (
                            ''
                          )}
                        </MenuItem>
                      </Link>}
                    </>
                  )}

                  {['superadmin', 'operation_admin', 'admin', 'photographer', 'accounting', 'broker', 'organizer'].includes(user.role) && config.payoutEnabled &&
                    <SubMenu label={<FormattedMessage className="menu-title" id="app.withdrawal" defaultMessage="Withdrawal" />}
                      className='subMenu'
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      icon={<div>
                        <WalletOutlined className='menu-icon' />
                        {window.matchMedia('(max-width: 750px)').matches
                          ? ''
                          : <span className="menu-title"><FormattedMessage id="app.withdrawal" defaultMessage="Withdrawal" /></span>
                        }
                      </div>}
                      title={window.matchMedia('(max-width: 750px)').matches
                        ? <span className='menu-sub'><FormattedMessage id="app.withdrawal" defaultMessage="withdrawal" /></span>
                        : ''
                      }
                    >
                      <Link to="/transfers"><MenuItem key="transfer-list">
                        <span className='menu-item'><FormattedMessage id="app.withdrawalRequests" defaultMessage="Withdrawal Requests" /></span>
                      </MenuItem></Link>
                      {['superadmin', 'operation_admin', 'admin', 'photographer', 'accounting'].includes(user.role) && <Link to="/transfers/create"><MenuItem key="transfer-create">
                        <span className='menu-item'><FormattedMessage id="app.createWithdrawalRequest" defaultMessage="Create Withdrawal Requests" /></span>
                      </MenuItem></Link>}
                      {['superadmin', 'operation_admin', 'admin', 'accounting', 'broker', 'photographer'].includes(user.role) && (
                        <Link to="/transfers/event/create"><MenuItem key="transfer-create-event">
                          <span className='menu-item'><FormattedMessage id="app.createWithdrawalRequestEvent" defaultMessage="Create Withdrawal Requests from Event" /></span>
                        </MenuItem></Link>
                      )}
                    </SubMenu>
                  }
                  {!['heyjom', 'indo', 'malay', 'viet', 'brunei', 'mas', 'runx'].includes(config.platform) && ['superadmin', 'admin', 'operation_admin', 'photographer', 'accounting'].includes(user.role) &&
                    <SubMenu label={<FormattedMessage id="app.credits" defaultMessage="Credits" />} className='subMenu'
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      icon={<div>
                        <CopyrightOutlined className='menu-icon' />
                        {window.matchMedia('(max-width: 750px)').matches
                          ? ''
                          : <span className="menu-title"><FormattedMessage id="app.credits" defaultMessage="Credits" /></span>
                        }
                      </div>}
                      title={window.matchMedia('(max-width: 750px)').matches
                        ? <span className='menu-sub'><FormattedMessage id="app.credits" defaultMessage="Credits" /></span>
                        : ''
                      }
                    >
                      <Link to="/credits/history"><MenuItem key="credits-history">
                        <span className='menu-item'><FormattedMessage id="app.creditsHistory" defaultMessage="Credits History" /></span>
                      </MenuItem></Link>
                      <Link to="/credits/buy"><MenuItem key="buy-credits">
                        <span className='menu-item'><FormattedMessage id="app.buyCredits" defaultMessage="Buy Credits" /></span>
                      </MenuItem></Link>
                      {['superadmin', 'admin', 'operation_admin', 'accounting'].includes(user.role) && (
                        <>
                          <Link to='/credits/events'><MenuItem key='event-credits'>
                            <span className='menu-item'><FormattedMessage id='app.eventCredits' defaultMessage='Event Credits' /></span>
                          </MenuItem></Link>
                          <Link to='/credits/add'><MenuItem key='add-credits'>
                            <span className='menu-item'><FormattedMessage id='app.addEventCredits' defaultMessage='Add Event Credits' /></span>
                          </MenuItem></Link>
                        </>
                      )}
                    </SubMenu>
                  }
                  {user.can('view_users') &&
                    <SubMenu label={<FormattedMessage className="menu-title" id="app.users" defaultMessage="Users" />} className='subMenu'
                      style={{marginTop: '10px', marginBottom: '10px'}}
                      icon={<div>
                        <UserOutlined className='menu-icon' />
                        {window.matchMedia('(max-width: 750px)').matches
                          ? ''
                          : <span className="menu-title"><FormattedMessage id="app.users" defaultMessage="Users" /></span>
                        }
                      </div>}
                      title={window.matchMedia('(max-width: 750px)').matches
                        ? <span className='menu-sub'><FormattedMessage id="app.users" defaultMessage="User" /></span>
                        : ''
                      }
                    >
                      {['superadmin', 'admin', 'operation_admin', 'accounting'].includes(user.role) && <Link to="/users"><MenuItem key="user-list">
                        <span className='menu-item'><FormattedMessage id="app.userList" defaultMessage="User List" /></span>
                      </MenuItem></Link>}
                      {['superadmin', 'admin', 'operation_admin', 'accounting', 'organizer'].includes(user.role) && <Link to="/partners"><MenuItem key="partners">
                        <span className='menu-item'><FormattedMessage id="app.partners" defaultMessage="Partners" /></span>
                      </MenuItem></Link>}
                      {['superadmin', 'admin', 'operation_admin', 'accounting', 'broker'].includes(user.role) && <Link to="/brokers"><MenuItem key="brokers">
                        <span className='menu-item'><FormattedMessage id="app.brokers" defaultMessage="Brokers" /></span>
                      </MenuItem></Link>}
                      {['superadmin', 'admin', 'accounting', 'salesperson'].includes(user.role) && <Link to="/salespersons"><MenuItem key="salespersons">
                        <span className='menu-item'><FormattedMessage id="app.salespersons" defaultMessage="salespersons" /></span>
                      </MenuItem></Link>}
                      {['superadmin', 'admin', 'accounting', 'salesperson'].includes(user.role) && <Link to="/photographers"><MenuItem key="photographer">
                        <span className='menu-item'><FormattedMessage id="app.photographer" defaultMessage="photographer" /></span>
                      </MenuItem></Link>}
                      {['superadmin', 'admin', 'operation_admin'].includes(user.role) && <Link to="/user/create"><MenuItem key="user-create">
                        <span className='menu-item'><FormattedMessage id="app.createUser" defaultMessage="Create User" /></span>
                      </MenuItem></Link>}
                    </SubMenu>
                  }
                  {user.can('view_coupons') &&
                    <SubMenu label={<FormattedMessage className="menu-title" id="app.coupons" defaultMessage="Coupons" />} className='subMenu'
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      icon={<div>
                        <TagOutlined className='menu-icon' />
                        {window.matchMedia('(max-width: 750px)').matches
                          ? ''
                          : <span className="menu-title"> <FormattedMessage id="app.coupons" defaultMessage="Coupons" /></span>
                        }
                      </div>}
                      title={window.matchMedia('(max-width: 750px)').matches
                        ? <span className='menu-sub'><FormattedMessage id="app.coupons" defaultMessage="Coupons" /></span>
                        : ''
                      }
                    >
                      <Link to="/coupon-types"><MenuItem key="coupon-type-list">
                        <span className='menu-item'><FormattedMessage id="app.couponTypeLists" defaultMessage="Coupon Type Lists" /></span>
                      </MenuItem></Link>
                      <Link to="/coupons"><MenuItem key="coupon-list">
                        <span className='menu-item'><FormattedMessage id="app.couponLists" defaultMessage="Coupon Lists" /></span>
                      </MenuItem></Link>
                      <Link to="/coupon-types/create"><MenuItem key="create-coupon-type">
                        <span className='menu-item'><FormattedMessage id="app.createCouponType" defaultMessage="Create Coupon Type" /></span>
                      </MenuItem></Link>
                      <Link to="/coupons/generate"><MenuItem key="create-coupon">
                        <span className='menu-item'><FormattedMessage id="app.generateCoupons" defaultMessage="Generate Coupons" /></span>
                      </MenuItem></Link>
                    </SubMenu>
                  }
                  {['superadmin', 'admin', 'operation_admin', 'accounting'].includes(user.role) &&
                    <Link to="/announcements/create"><MenuItem className='subMenu'
                      // style={{ marginTop: '10px', marginBottom: '30px'}}
                      key="newAnnouncement" icon={<div>
                        <MessageOutlined className='menu-icon '/>
                        {window.matchMedia('(max-width: 750px)').matches
                          ? ''
                          : <span className="menu-title"><FormattedMessage id="app.announcements" defaultMessage="Announcement" /></span>
                        }
                      </div>}>
                      {window.matchMedia('(max-width: 750px)').matches
                        ? <span className='menu-sub'><FormattedMessage id="app.announcements" defaultMessage="Announcement" /></span>
                        : ''
                      }
                    </MenuItem></Link>
                  }
                  {['superadmin', 'admin', 'operation_admin', 'accounting', 'customerService'].includes(user.role) &&
                    <Link to="/photos/report"><MenuItem key="photo-report" className='subMenu'
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      icon={<div>
                        <PictureOutlined className='menu-icon' />
                        {window.matchMedia('(max-width: 750px)').matches
                          ? ''
                          : <span className="menu-title"><FormattedMessage id="app.report" defaultMessage="Reported" /></span>
                        }
                      </div>}>

                      {window.matchMedia('(max-width: 750px)').matches
                        ? <span className='menu-sub'><FormattedMessage id="app.report" defaultMessage="Reported" /></span>
                        : ''
                      }
                    </MenuItem></Link>
                  }
                  {['superadmin', 'admin', 'operation_admin', 'accounting'].includes(user.role) &&
                    <Link to="/packages"><MenuItem key="photo-packages" className='subMenu'
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      icon={<div>
                        <PercentageOutlined className='menu-icon' />
                        {window.matchMedia('(max-width: 750px)').matches
                          ? ''
                          : <span className="menu-title"><FormattedMessage id="app.packages" defaultMessage="Packages" /></span>
                        }
                      </div>}>

                      {window.matchMedia('(max-width: 750px)').matches
                        ? <span className='menu-sub'><FormattedMessage id="app.packages" defaultMessage="Packages" /></span>
                        : ''
                      }
                    </MenuItem></Link>
                  }
                  {/* <MenuItem key="locale-switcher" style={{marginBottom: '15px' ,textAlign: 'center' }}>
                      <LocaleSwitcher />
                    </MenuItem> */}
                  {/* <div style={{ textAlign: 'center', position: 'absolute', bottom: 20, width: '100%' }}> */}
                  {/* </div> */}

                </Menu>

                <div className='footer' style={{textAlign: 'center'}}>
                  <Popover id="profile-popover" content={content} placement="top" trigger="click">
                    {window.matchMedia('(max-width: 750px)').matches
                      ? <div><Avatar icon={<div>
                        <UserOutlined />
                      </div>}/> <span className='menu-sub' style={{ margin: '5px' }}>{user.username}</span></div>
                      : <Avatar>{user.username}</Avatar>
                    }
                  </Popover>
                </div>
              </Sidebar>
            </div>
          </MainMenu>
          <div className="maincontent" style={{ margin: '0px 3px' }}>
            {/* {verified === false && <Alert banner
                type="info"
                className="nonPrintDiv"
                style={{ whiteSpace: 'pre-line' }}
                message={
                    <div style={{
                      display: '-webkit-box',
                      'WebkitLineClamp': '1',
                      'WebkitBoxOrient': 'vertical',
                      overflow: 'hidden'
                    }}>
                      <span>{config.lineProtographer}</span>
                  </div>
                }
              />} */}
            {announcement && !['organizer'].includes(user.role) &&
              <Alert banner
                type="info"
                className="nonPrintDiv"
                style={{ whiteSpace: 'pre-line' }}
                message={
                  // announcement.userRole &&
                  <div onClick={() => history.push(`/announcements/${announcement._id}`)}>
                    <div style={{
                      display: '-webkit-box',
                      'WebkitLineClamp': '1',
                      'WebkitBoxOrient': 'vertical',
                      overflow: 'hidden'
                    }}>
                      <span style={{ fontWeight: 'bold' }}>{announcement.title} </span>
                      <span>
                        <Linkify componentDecorator={(href, text, key) => (
                          <a href={href} key={key} style={{ textDecoration: 'underline' }}>
                            {text}
                          </a>
                        )}>
                          {announcement.message}
                        </Linkify>
                      </span>
                      <div style={{ float: 'right', clear: 'both' }}>Read more...</div>
                    </div>
                    {/* <span style={{ height: '1.5em', overflow: 'hidden' }}>{announcement.message}</span> */}
                  </div>
                }
              />
            }
            <Suspense fallback={<div>Loading...</div>}>
              {(window.matchMedia('(max-width: 750px)').matches) ? <div className="btn-toggle">
                <BarsOutlined onClick={this.handleToggleSidebar} style={{ fontSize: '24px', color: '#000', margin: '10px' }} />
              </div> : ''}
              <Switch>
                <Route
                  exact
                  path="/"
                  /* {component={Dashboard}} */
                  render={() => <Redirect to="/events" />}
                />
                <Route
                  exact
                  path="/dashboard/business-advisor"
                  component={Dashboard}
                />
                <Route
                  exact
                  path="/orders"
                  component={Orders}
                />
                {/* <Route
                exact
                path="/orders/months"
                component={MonthsOrders}
              /> */}
                <Route
                  exact
                  path="/orders\:months"
                  component={MonthStats}
                />
                {/*
                  role === 'admin'
                    ? <Route
                      exact
                      path="/orders/months"
                      component={MonthsOrders}
                    /> : null
                */}
                {/* <Route
                exact
                path="/orders/:year/:month"
                component={MonthsOrdersDetail}
              /> */}
                <Route
                  exact
                  path="/orders/events"
                  // component={EventsOrders}
                  render={props => <EventsOrders {...props} />}
                />
                <Route
                  exact
                  path="/orders\:events" // eslint-disable-line no-useless-escape
                  // component={EventsOrders}
                  render={props => <EventSummary {...props} />}
                />
                {/*
                  <Route
                    exact
                    path="/orders/period"
                    component={PeriodOrders}
                  />
                */}
                <Route
                  exact
                  path="/orders/:orderId"
                  component={Order}
                />
                <Route
                  exact
                  path="/events/create"
                  component={EventCreate}
                />
                <Route
                  exact
                  path="/events/:id/edit"
                  component={EventEdit}
                />
                <Route
                  exact
                  path="/events"
                  component={Events}
                />
                <Route
                  exact
                  path="/events/:eventId"
                  component={Event}
                />
                <Route
                  exact
                  path="/events/:eventId/view"
                  component={EventView}
                />
                <Route
                  exact
                  path="/events/:eventId/upload"
                  component={PhotoUpload}
                />
                <Route
                  exact
                  path="/events/:eventId/photos"
                  component={EventPhotos}
                />
                <Route
                  exact
                  path="/photos/upload"
                  component={PhotoUpload}
                />
                <Route
                  exact
                  path="/photos/report"
                  component={ReportedPhotos}
                />
                <Route
                  exact
                  path="/photos/:photoId"
                  component={Photo}
                />
                <Route
                  exact
                  path="/photos/:photoId/edit"
                  component={PhotoTag}
                />
                <Route
                  exact
                  path="/profile/edit"
                  component={UserProfileEdit}
                />
                <Route
                  exact
                  path="/profile"
                  component={ViewProfile}
                />
                <Route
                  exact
                  path="/transfers/create"
                  component={TransferRequestCreate}
                />
                <Route
                  exact
                  path="/transfers/event/create"
                  component={TransferRequestEventCreate}
                />
                <Route
                  exact
                  path="/transfers"
                  component={TransferRequestList}
                />
                <Route
                  exact
                  path="/users/:userId/edit"
                  component={UserEdit}
                />
                <Route
                  exact
                  path="/user/new"
                  component={UserCreateAdmin}
                />
                <Route
                  exact
                  path="/users"
                  component={UserList}
                />
                <Route
                  exact
                  path="/user/create"
                  component={UserEdit}
                />
                <Route
                  exact
                  path="/coupon-types"
                  component={CouponTypes}
                />
                <Route
                  exact
                  path="/coupon-types/create"
                  component={CouponTypeCreate}
                />
                <Route
                  exact
                  path="/coupon-types/:couponTypeId/edit"
                  component={CouponTypeEdit}
                />
                <Route
                  exact
                  path="/coupons/generate"
                  component={GenerateCoupons}
                />
                <Route
                  exact
                  path="/coupons"
                  component={Coupons}
                />
                <Route
                  exact
                  path="/coupon-types/:couponTypeId/coupons/:couponId?"
                  component={CouponUsedList}
                />
                <Route
                  exact
                  path="/announcements/create"
                  component={AnnouncementCreate}
                />
                <Route
                  exact
                  path="/announcements/:announcementId"
                  component={Announcements}
                />
                <Route
                  exact
                  path="/announcements"
                  component={Announcements}
                />
                <Route
                  exact
                  path="/events\:balance\:organizer/:organizerId?"
                  component={OrganizerEvents}
                />
                <Route
                  exact
                  path="/events\:balance\:broker/:brokerId?"
                  component={BrokerEvents}
                />
                <Route
                  exact
                  path="/events\:balance\:salesperson/:salespersonId?"
                  component={SalespersonEvents}
                />
                <Route
                  exact
                  path="/events\:balance\:photographer/:photographerId?"
                  component={PhotographerEvents}
                />
                {/* <Route
                exact
                path="/events\:balance\:organizers/:organizerId?"
                component={OrganizerBalance}
              /> */}
                <Route
                  exact
                  path="/partners"
                  component={Partners}
                />
                <Route
                  exact
                  path="/partners/add"
                  component={PartnerAdd}
                />
                <Route
                  exact
                  path="/partners/:partnerId/edit"
                  component={PartnerEdit}
                />
                {/* <Route
                exact
                path="/partners/:partnerId"
                component={OrganizerEvents}
              /> */}
                <Route
                  exact
                  path="/brokers"
                  component={Brokers}
                />
                <Route
                  exact
                  path="/brokers/add"
                  component={BrokerAdd}
                />
                <Route
                  exact
                  path="/brokers/:brokerId/edit"
                  component={BrokerEdit}
                />
                <Route
                  exact
                  path="/brokers/:brokerId"
                  component={BrokerEvents}
                />
                <Route
                  exact
                  path="/salespersons"
                  component={Salespersons}
                />
                <Route
                  exact
                  path="/photographers"
                  component={Photographers}
                />
                <Route
                  exact
                  path="/credits/history"
                  component={CreditHistory}
                />
                <Route
                  exact
                  path="/credits/buy"
                  component={BuyCredits}
                />
                <Route
                  exact
                  path="/credits/events"
                  component={EventCreditStats}
                />
                <Route
                  exact
                  path="/credits/add"
                  component={AddEventCredits}
                />
                <Route
                  exact
                  path="/packages"
                  component={Packages}
                />
                <Route
                  exact
                  path="/packages/create"
                  component={PackageCreate}
                />
                <Route
                  exact
                  path="/packages/:packageId/edit"
                  component={PackageEdit}
                />
                <Route
                  exact
                  path="/mergeorganizers"
                  component={Mergeorganizers}
                />
              </Switch>
            </Suspense>
          </div>
        </div>
      </AppWrapper>
    </React.Fragment>)
  }
}

export default compose(
  withApollo
)(App)
